<template>
<div class="relative w-11/12 flex flex-col bg-primary-darkest" :class="(open) ? 'active-button' : 'user'" v-click-outside="hide">
  <div v-if="open">
    <ul class="absolute bottom-14 w-full flex flex-col justify-items-center" :class="(open) ? 'active-button' : 'user'">
      <li class="px-2 py-1">
        <slot></slot>
      </li>
        <li class="px-2 py-1">
          <a href="https://openwebinars.net/academia/" class="flex w-full pr-4 rounded py-2 hover:bg-primary-darker">
          <SVGIcons class="svg-16 mt-1 ml-5 mr-2" :icon="'academy'" />
          Ir a la academia
          </a>
        </li>
        <li class="px-2 py-1">
          <router-link
            class="flex w-full pr-4 rounded py-2 hover:bg-primary-darker"
            :to="{name: 'profile-organization'}"
          >
          <SVGIcons class="svg-20 mt-1 ml-5 mr-2" :icon="'settings'" />
          Configuración
          </router-link>
        </li>
        <li class="px-2 py-1">
          <a class="flex w-full pr-4 rounded py-2 hover:bg-primary-darker" href="https://openwebinars.net/accounts/logout/">
            <SVGIcons class="svg-20 mt-1 ml-5 mr-2" :icon="'logout'" />
            Cerrar sesión
          </a>
        </li>
    </ul>
  </div>
  <hr v-if="open" class="my-3 mx-3">
  <button @click="toggleMenu" class="flex w-full items-center px-2 mx-2">
      <figure class="flex-none flex relative flex-col items-center">
        <div class="bg-grey-lighter rounded-full overflow-hidden" :class="(xl)?'w-14 h-14':'w-8 h-8'">
          <img class="cover" :src="user.avatar_url" alt="Avatar" :class="(xl)?'w-14 h-14':'w-8 h-8'">
        </div>
      </figure>
      <div v-if="!collapsedMenu" class="flex flex-col items-start text-left">
        <h2 class="font-semibold w-36 ml-1 truncate" :class="(xl)?'text-md':'text-xs'">{{user.first_name}} {{user.last_name}}</h2>
        <div class="text-xs ml-1 font-normal w-36 truncate"><span class="capitalize">{{selected_business.role == 'staff' ? 'Gestor' : selected_business.role}}</span> en {{selected_business.name}}</div>
      </div>
      <SVGIcons v-if="!collapsedMenu" class="svg-18 mt-1 ml-2 absolute bottom-18 right-2" :icon="'arrows'" />
  </button>
</div>
</template>

<script>
import { mapState } from 'vuex'
import SVGIcons from '../components/Layout/SVGIcons.vue'
export default {
  name: 'UserMenu',
  components: {
    SVGIcons
  },
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          avatar_url: 'https://openwebinars.net/static/images/onboarding/avatars/2.svg',
          first_name: 'NoName',
          last_name: 'NoLasName',
          email: 'NoEMail@NoEmail.com'
        }
      }
    },
    text: {
      type: String
    },
    xl: {
      type: Boolean
    }
  },
  data () {
    return {
      open: false
    }
  },
  computed: {
    ...mapState(['active_business', 'business', 'collapsedMenu']),
    selected_business () {
      return this.business.find((el) => el.business_id === this.active_business)
    }
  },
  methods: {
    toggleMenu () {
      this.open = !this.open
    },
    hide () {
      this.open = false
    }
  },
  directives: {
    clickOutside: {
      mounted (el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            binding.instance.hide()
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unmounted (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      }
    }
  }
}
</script>

<style scoped>
.user{
  @apply text-white hover:bg-secondary-dark py-2 rounded
}
.active-button{
  @apply text-white py-2 rounded bg-secondary-dark
}
</style>
