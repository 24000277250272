<script setup>
import { ref, defineExpose } from 'vue'
import { api } from '../../api/instances'
import { useStore } from 'vuex'
import Overlay from '@/components/Modals/components/Overlay.vue'
import Modal from '@/components/Modals/components/Modal.vue'
import ModalHeader from '@/components/Modals/components/ModalHeader.vue'
import ModalTitle from '@/components/Modals/components/ModalTitle.vue'
import ModalBody from '@/components/Modals/components/ModalBody.vue'
import UiButton from '@/components/Layout/UI/UiButton.vue'

const store = useStore()
const addToastMessage = (data) => store.commit('addToastMessage', data)
const open = ref(false)
const days = ref(0)
const emit = defineEmits(['update'])
const employee_id = ref(0)
const modal = async (action, obj = null) => {
  if (action === 'show') {
    open.value = true
    if (obj) {
      employee_id.value = obj.employee_id
    }
  }
  if (action === 'hide') {
    open.value = false
  }
}

defineExpose({ modal })
const incrementDays = () => {
  if (days.value < 30) {
    days.value = days.value + 1
    calculateDate()
  }
}
const decreaseDays = () => {
  if (days.value > 0) {
    days.value = days.value - 1
    calculateDate()
  }
}
const checkDays = () => {
  if (days.value > 30) {
    days.value = 30
    calculateDate()
  }
  if (days.value < 0) {
    days.value = 0
    calculateDate()
  }
}
const dateActivation = ref('')
const calculateDate = () => {
  const date = new Date()
  date.setDate(date.getDate() + days.value)
  dateActivation.value = date.toLocaleDateString()
}
const programDisabled = () => {
  api.patch(`/business/license/${employee_id.value}/?jarvis=jarvis`, {
    deactivate_after: days.value
  })
    .then(() => {
      emit('update')
      addToastMessage({
        title: '¡Éxito!',
        status: 'success',
        message: 'Licencia programada correctamente'
      })
      closeModal()
    })
    .catch((err) => {
      console.log(err)
      addToastMessage({
        title: '¡Error!',
        status: 'danger',
        message: 'Ha habido un problema al programar la licencia'
      })
    })
}
const closeModal = () => {
  open.value = false
  days.value = 0
}

</script>
<template>
  <Overlay class="z-50" :open="open">
    <Modal class="mt-24">
      <ModalHeader @close="closeModal">
        <ModalTitle>
          Programar desactivación
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <h3 class="text-[#344054] font-medium text-sm mb-3">Selecciona cuantos días deseas que este activa la licencia</h3>
        <div class="flex items-center">
          <div class="border-[#D0D5DD] border rounded w-[61px] h-[40px] flex items-center justify-between px-1 overflow-hidden">
            <input v-model="days" @change="checkDays" max="30" class="w-[20px]" type="number">
            <div class="flex flex-col justify-end">
              <button @click="incrementDays()" class="mb-2">
                <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 0L9.33013 6.75H0.669873L5 0Z" fill="#505050"/>
                </svg>
              </button>
              <button @click="decreaseDays()">
                <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 7L9.33013 0.25H0.669873L5 7Z" fill="#505050"/>
                </svg>
              </button>
            </div>
          </div>
          <span v-if="dateActivation && days > 0" class="text-sm ml-3 text-[#505050]">Fecha de desactivación {{ dateActivation }}</span>
        </div>
        <span class="text-2xs text-[#404040]">Max. 30 días</span>
      </ModalBody>
      <div class="w-full flex justify-center pb-7">
        <UiButton :disabled="days === 0" :size="'btn-lg'" :type="(days === 0) ? 'btn-disabled':'btn-primary'" @click="programDisabled">Aceptar</UiButton>
      </div>
    </Modal>
  </Overlay>
</template>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button  {
  opacity: 0;
  display: none;
}

input:focus {
  outline: none;
}
/* Para Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Para ambos (WebKit y Firefox) */
input {
  appearance: none;
  margin: 0;
}
</style>
