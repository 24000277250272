<template>
<div>
  <section class="container mx-auto px-12 mt-14">
    <div class="flex justify-end">
      <div class="w-5/12 text-xs text-primary-dark flex flex-col items-end justify-center pt-1 pr-5">
        ¿Te falta tiempo? Nuestra IA puede <br>analizar y ejecutar las mejoras por ti.
      </div>
      <div class="w-auto">
        <UiButton @click="getMagicData" class="whitespace-nowrap" :type="'secondary'">
          Ejecutar todas las mejoras con IA <IAIcon :class="{'animate-spin' : magicLoading }" class="w-6 h-6 ml-2" />
        </UiButton>
      </div>
    </div>
    <div class="flex mt-6">
      <div class="w-full">
      <UiBox>
        <Loading v-if="generalConunter === null" />
        <div v-else class="flex">
          <div class="w-3/12">
            <div class="max-w-[200px] m-auto">
              <div class="flex items-center font-medium">
                <SpeedometerIcon class="w-6 h-6 mr-2" />
                Calidad de la gestión
              </div>
              <div class="text-center">{{(permissions.includes('can_create_teams') ? 'Organización' : (teamSuggestions.length > 1) ? 'Tus equipos': 'Tu equipo')}}</div>
              <Speedometer :degree="parseInt(degree)" />
              <div class="text-sm text-grey text-center">La calidad de la gestión es: </div>
              <div class="font-bold text-center" :style="`color:${degreeState.color}`" >{{ degreeState.text }}</div>
            </div>
          </div>
          <div class="w-9/12 pl-12 flex flex-wrap">
<!--             <div class="flex item-center font-bold text-primary-dark">
              <InfoCircleIcon class="w-6 h-6 mr-2" />
              Información de la calidad de la gestión
            </div> -->
            <Visibility :permission="'can_create_teams'">
              <div class="w-1/2 px-3 mb-4" v-if="generalConunter.available_licenses_for_teams >= 0">
                <div class="shadow-md rounded p-6">
                  <div>Licencias disponibles para asignar a equipos</div>
                  <div class="text-2xl text-primary-darker font-bold">{{generalConunter.available_licenses_for_teams}}</div>
                </div>
              </div>
          </Visibility>
            <div class="w-1/2 px-3 mb-4" v-if="generalConunter.available_licenses_for_people >= 0">
              <div class="shadow-md rounded p-6">
                <div>Licencias disponibles sin activar a usuarios</div>
                <div class="text-2xl text-primary-darker font-bold">{{generalConunter.available_licenses_for_people}}</div>
              </div>
            </div>
            <div class="w-1/2 flex px-3 mb-4">
              <RouterLink v-if="generalConunter.unactive_licenses >= 0" class="group relative flex w-full flex-col shadow-md rounded p-6 hover:bg-primary-200" :to="'/solicitudes/liberar-licencias'">
                <CornerArrowIcon class="group-hover:opacity-100 opacity-0 absolute right-2 top-2" />
                <div>Licencias activadas a usuarios sin uso</div>
                <div class="text-2xl text-primary-darker font-bold">{{generalConunter.unactive_licenses}}</div>
              </RouterLink>
            </div>
            <div class="w-1/2 px-3 mb-4">
              <RouterLink class="group relative flex flex-col justify-between shadow-md rounded p-6 hover:bg-primary-200" v-if="generalConunter.pending_requests >= 0" :to="'/gestion/solicitudes'">
                <CornerArrowIcon class="group-hover:opacity-100 opacity-0 absolute right-2 top-2" />
                <div>Solicitudes de licencia sin contestar</div>
                <div class="text-2xl text-primary-darker font-bold">{{ generalConunter.pending_requests }}</div>
                <Visibility :permission="'can_see_assistant_settings'">
                <div class="flex items-center justify-end">Gestión automática
                  <Tooltip class="ml-1">
                    <li>
                      <p>Activa las licencias automáticamente cuando haya licencias disponibles o sin actividad.</p>
                      <RouterLink :to="'/gestion/asistente/configuracion'">
                        <b class="font-bold text-primary-dark">Configura la gestión automática</b>
                      </RouterLink>
                    </li>
                  </Tooltip>
                  <ToggleButton @click.prevent.stop="activateAutoRotation" v-model="isAutomaticRotation" :loading="loading" class="ml-2"/>
                </div>
                </Visibility>
              </RouterLink>
            </div>
            <div v-if="isAllOK" class="w-1/2 px-3 mb-4 flex">
              <div class="flex flex-col justify-between shadow-md rounded p-6">
                <div>La media de horas de uso de tus licencias es</div>
                <div class="text-2xl text-primary-darker font-bold">{{ $filters.roundedHoursCounterBySeconds(generalConunter.average_request_time) }} horas</div>
              </div>
            </div>
            <div v-if="isAllOK" class="w-1/2 px-3 mb-4 flex">
              <div class="flex flex-col justify-between shadow-md rounded p-6">
                <div>La media de respuesta a tus solicitudes de licencia es</div>
                <div class="text-2xl text-primary-darker font-bold">{{ $filters.dayCounterBySeconds(generalConunter.average_license_usage) }} día{{$filters.dayCounterBySeconds(generalConunter.average_license_usage) > 1?'s':''}}</div>
              </div>
            </div>
          </div>
        </div>
      </UiBox>
      </div>
    </div>
    <Visibility :permission="'can_create_teams'">
    <div v-if="isGeneralLicenses" class="flex mt-6">
      <div class="w-full">
        <UiBox>
        <Loading v-if="generalConunter === null" />
         <div v-else class="w-full">
          <div class="font-bold flex items-center text-primary-dark">
            <WandIcon class="w-6 h-6 mr-2" />
            Sugerencias para mejorar la calidad de la gestión de la organización
          </div>
          <TabFolder :order="1" :title="'Reasigna estas licencias a equipos'" :value="generalConunter.available_licenses_for_teams" :blur="false">
            <div class="mt-2 p-2">
              <div class="flex justify-between items-center">Nuestro algoritmo ordena equipos por necesidad de licencias.
                <UiButton
                 @click="updateMassiveTeams"
                 class="w-[182px] whitespace-nowrap justify-center"
                 :type="'secondary'">
                  Reasignar todas
                  <LoadingIcon v-if="loadingAllLicenses" :color="'#fff'" class="w-5 h-5 ml-2" />
                  <WandIcon v-else :color="'#fff'" class="w-5 h-5 ml-2" />
                </UiButton>
              </div>
              <div v-for="team in teamSuggestions" :key="team.id" class="flex mt-5">
                <div class="border border-primary-light p-2 rounded flex items-center w-full">
                  <div class="w-4/12 font-bold">
                    <div class="w-full overflow-hidden text-ellipsis whitespace-nowrap">{{ team.name }}</div>
                  </div>
                  <div class="text-sm text-grey-dark pl-4">
                    Usuarios: <span>{{ team.users }} Usuarios</span>
                    Licencias asignadas: <span>{{ team.licenses }} Licencias</span>
                  </div>
                </div>
                <div class="pl-2">
                  <UiButton @click.prevent="updateTeamLicenses(team.id, team.target_licenses, team.licenses_to_assign)" class="w-[180px] whitespace-nowrap" :type="'outline-secondary'">
                    {{(team.licenses_to_assign > 0) ? 'Asignar' : 'Quitar'}} {{ Math.abs(team.licenses_to_assign) }} licencias
                  </UiButton>
                </div>
              </div>
            </div>
          </TabFolder>
        </div>
      </UiBox>
      </div>
    </div>
    </Visibility>
    <div class="flex mt-6">
      <div class="w-full">
      <UiBox>
         <div class="w-full">
          <div class="flex justify-between">
            <div class="font-bold flex items-center text-primary-dark">
              <WandIcon class="w-6 h-6 mr-2" />
              Sugerencias para mejorar la calidad de la gestión del equipo
            </div>
            <div v-if="generalConunter && generalConunter.managed_teams.length > 1" class="flex items-center">
              <div class="flex items-center mr-2">
                <select @change="getTeamInfo" class="border max-w-[130px] border-grey-light rounded p-1 text-sm" v-model="selectedTeam" v-if="generalConunter">
                  <option v-for="team in generalConunter.managed_teams" :key="team.id" :value="team.id" >{{team.name}}</option>
                </select>
              </div>
              <TeamSpeedometer :degree="teamScore" :name="teamName"/>
            </div>
          </div>
          <TabFolder
            v-if="SelectedTeamData && SelectedTeamData.enable_suggestions.length > 0"
            :order="(isGeneralLicenses)? 2 : 1"
            :title="'Activa estas licencias inactivas a estos usuarios'"
            :value="SelectedTeamData.enable_suggestions.length"
            :blurMessage="'Debes asignar todas las licencias antes de seguir mejorando la calidad de la gestión del equipo'"
            :blur="generalConunter.available_licenses_for_teams > 0">
            <div class="mt-2 p-2">
              <div class="flex justify-between items-center">Nuestro algoritmo ordena a los usuarios según su nivel de urgencia de formación.
                <UiButton
                 @click="activateLicenses(allEnableUserIds)"
                  class="w-[167px] whitespace-nowrap justify-center"
                   :type="'secondary'">
                   Asignar todas
                  <LoadingIcon v-if="loadingEnableAll" :color="'#fff'" class="w-5 h-5 ml-2" />
                  <WandIcon v-else :color="'#fff'" class="w-5 h-5 ml-2" />
                  </UiButton>
              </div>
              <div v-for="user in SelectedTeamData.enable_suggestions" :key="user.id" class="flex mt-5">
                <div class="border border-primary-light p-2 rounded flex items-center w-full">
                  <div class="w-1/2">
                    <UserIdentity large :user="user.user_to_enable" dark />
                  </div>
                  <div class="text-sm text-grey-dark pl-4 w-1/2 flex justify-center pr-4 gap-2">
                    <div>
                      Equipo: <span class="font-medium">{{ user.user_to_enable.team }}</span>
                    </div>
                    <div>
                      Ultima activación: <span class="font-medium">{{ $filters.shortDate(user.user_to_enable.last_time_active) }}</span>
                    </div>
                  </div>
                </div>
                <div class="pl-2 flex items-center">
                  <UiButton @click="activateLicenses([user.user_to_enable.id])" class="whitespace-nowrap" :type="'outline-secondary'">Asignar licencia</UiButton>
                </div>
              </div>
            </div>
          </TabFolder>
          <TabFolder
           v-if="SelectedTeamData && SelectedTeamData.rotate_suggestions.length > 0"
           :order="(SelectedTeamData && SelectedTeamData.enable_suggestions.length > 0 ? 1 : 0) + (isGeneralLicenses ? 1 : 0) + 1"
           :title="'Rota estas licencias entre estos usuarios'"
           :value="SelectedTeamData.rotate_suggestions.length"
           :blurMessage="'Debes activar la licencias inactivas antes de seguir mejorando la calidad de la gestión del equipo'"
           :blur="generalConunter.available_licenses_for_teams > 0 || SelectedTeamData.enable_suggestions.length > 0" >
            <div class="mt-2 p-2">
              <div class="flex justify-between items-center">Rotación sugerida a desactivar licencia a usuarios inactivos en favor de aquellos con necesidad de formarse.
                <UiButton
                 @click="rotateLicenses(deactivateIds, activateIds)"
                 class="md:w-[202px] xl:w-[167px] whitespace-nowrap justify-center"
                :type="'secondary'">
                  Rotar todas
                  <LoadingIcon v-if="loadingRotar" :color="'#fff'" class="w-5 h-5 ml-2" />
                  <WandIcon v-else :color="'#fff'" class="w-5 h-5 ml-2" />
                </UiButton>
              </div>
              <div v-for="rotate in SelectedTeamData.rotate_suggestions" :key="rotate" class="flex mt-5">
                <div class="w-full">
                  <div class="border border-green-200 p-2 rounded flex justify-between items-center w-full bg-green-50">
                    <div class="w-[260px] pr-5">
                      <UserIdentity :user="rotate.user_to_enable" dark />
                    </div>
                    <div class="text-xs text-grey-dark pl-4 w-1/3">
                      Ultima activación de licencia: <span class="whitespace-nowrap font-medium">{{$filters.shortDate(rotate.user_to_enable.last_time_active)}}</span>
                    </div>
                    <div class="w-1/3 text-sm whitespace-nowrap text-bold text-green-600 pl-4 flex justify-end pr-4">
                      Activar licencia
                    </div>
                  </div>
                  <div class="border border-red-200 p-2 rounded flex justify-between items-center w-full bg-red-50 mt-1.5">
                    <div class="w-[260px] pr-5">
                      <UserIdentity :user="rotate.user_to_disable" dark />
                    </div>
                    <div class="text-xs text-grey-dark pl-4 w-1/3">
                      Usuario inactivo desde hace <span class="whitespace-nowrap font-medium">{{$filters.DayCounterByDate(rotate.user_to_disable.last_time_active)}} días</span>
                    </div>
                    <div class="w-1/3 text-sm whitespace-nowrap text-bold text-red-600 pl-4 flex justify-end pr-4">
                      Desactivar licencia
                    </div>
                  </div>
                </div>
                <div class="pl-4 flex items-center">
                  <UiButton @click="rotateLicenses([rotate.user_to_disable.id], [rotate.user_to_enable.id])" class="whitespace-nowrap" :type="'outline-secondary-lighter'">Rotar licencia <CircularArrowsIcon class="ml-2" /> </UiButton>
                </div>
              </div>
            </div>
          </TabFolder>
          <div v-if="SelectedTeamData && SelectedTeamData.rotate_suggestions.length == 0 && SelectedTeamData.enable_suggestions.length == 0" class="flex justify-center items-center mt-8">
            <div class="text-center">
              <div class="text-2xl font-medium text-primary-dark">¡Enhorabuena tienes tu gestión al día!</div>
              <div>No tienes sugerencias ya que no tienes ni licencias ni usuarios inactivos</div>
            </div>
            <div class="pl-4">
              <SuccessImg class="w-[150px] h-[118px]" />
            </div>
          </div>
        </div>
      </UiBox>
      </div>
    </div>
  </section>
  </div>
  <Overlay :open="magicModal">
    <Modal>
      <ModalHeader @close="closeModal" :close="!executingMagic && magicFinished === false">
        <ModalTitle v-if="!executingMagic && magicFinished === false" class="flex items-center">
          Asistente de gestión <span class="bg-ow-primary text-white text-sm font-semibold ml-2 px-2.5 py-1 rounded-xl">PRO</span>
        </ModalTitle>
        <ModalTitle v-else class="flex items-center">
          Ejecutando el asistente de gestión <IAIcon :color="'#1D2939'" class="w-6 h-6 ml-2" />
        </ModalTitle>
      </ModalHeader>
      <ModalBody v-if="canSeeIA">
        <IATyping v-if="magicLoading" />
        <div v-else>
          <div v-if="!magicFinished" class="custom-scroll max-modal-scroll">
            <div class="mb-6" v-if="magicGeneralIds.length > 0">
              <div class="flex items-center font-bold text-primary-dark">
                <WandIcon class="w-6 h-6 mr-2" /> Mejoras para la calidad de la gestión de la organización
              </div>
              <IAAction :id="team.id" :action="!magicGeneralIds.includes(team.id)" v-for="team in magicData.general" :key="team.id">
                la licencia al equipo <b class="ml-1 text-primary-dark">{{team.name}}</b>
              </IAAction>
            </div>
            <div class="mb-6" v-for="team in magicData.teams" :key="team.id">
              <div class="flex items-center font-bold text-primary-dark">
                <WandIcon class="w-6 h-6 mr-2" /> Mejoras para la calidad de la gestión del equipo “{{team.name}}”
              </div>
              <IAAction
                :states="user.action == 'enable' ? ['Activar', 'Activando', 'Finalizando activación de ', 'Activada'] : ['Rotar', 'Rotando', 'Finalizando rotación de', 'Rotada']"
                @finish="removeTeamIds"
                :id="user.id_to_enable" :action="!magicTeamsIds.includes(user.id_to_enable)" v-for="user in team.suggestions" :key="user.id_to_enable">
               <span v-if="user.action == 'enable'" > la licencia al usuario {{user.user}}</span>
                <span v-if="user.action == 'rotate'" > la licencia del usuario {{user.user_to_disable}} a {{user.user_to_enable}}</span>
              </IAAction>
            </div>
          </div>
          <div class="flex flex-col justify-center font-bold text-primary-dark items-center" v-else>
            <div>
              ¡Todas las mejoras han sido ejecutadas con exito!
            </div>
            <FinishImage />
          </div>
        </div>
      </ModalBody>
      <div v-if="canSeeIA">
        <ModalFooter v-if="!magicLoading && !isAllMagicOK">
          <div  class="w-full justify-center flex gap-5 pt-5">
            <UiButton v-if="!executingMagic && magicFinished === false" @click="magicUpdateMassiveTeams" class="whitespace-nowrap" :type="'secondary'">
              Ejecutar todas las mejoras con IA <IAIcon :class="{'animate-spin' : magicLoading }" class="w-6 h-6 ml-2" />
            </UiButton>
            <UiButton v-if="magicFinished" :class="{'disabled' : !magicFinished}" @click="closeModal" class="w-[182px] whitespace-nowrap justify-center" :type="'secondary'">Aceptar</UiButton>
          </div>
        </ModalFooter>
        <ModalBody v-if="isAllMagicOK">
          <div class="text-xl py-8">Tienes tu gestión al día, ¡enhorabuena! 🎉</div>
        </ModalBody>
      </div>
      <ModalBody v-else>
        <div class="text-xl py-8 flex items-center">
          <InfoCircleIcon :color="'#FDBA74'" class="w-6 h-6 mr-2" />
          <span class="text-[#FDBA74] text-base">La ejecución de todas las mejoras con IA solo está disponible con el Plan Pro. Si deseas activarlo, por favor, <u class="text-primary-dark">contacta con tu Customer Succes</u></span>
        </div>
      </ModalBody>
      <ModalFooter>
        <div v-if="isAllMagicOK" class="w-full justify-center flex gap-5 pt-5">
          <UiButton @click="closeModal" class="w-[182px] whitespace-nowrap justify-center" :type="'secondary'">Aceptar</UiButton>
        </div>
      </ModalFooter>
    </Modal>
  </Overlay>
  <DialogBox ref="user-dialog" />
</template>

<script setup>
import Visibility from '@/components/Visibility.vue'
import UserIdentity from '@/components/UserIdentity.vue'
import DialogBox from '@/components/DialogBox.vue'
import UiBox from '@/components/Layout/UI/UiBox.vue'
import IAIcon from '@/components/Icons/IAIcon.vue'
import SpeedometerIcon from '@/components/Icons/SpeedometerIcon.vue'
import InfoCircleIcon from '@/components/Icons/InfoCircleIcon.vue'
import CircularArrowsIcon from '@/components/Icons/CircularArrowsIcon.vue'
import WandIcon from '@/components/Icons/WandIcon.vue'
import Speedometer from './components/Speedometer.vue'
import TeamSpeedometer from './components/TeamSpeedometer.vue'
import SuccessImg from './components/SuccessImg.vue'
import Loading from './components/Loading.vue'
import UiButton from '@/components/Layout/UI/UiButton.vue'
import CornerArrowIcon from '@/components/Icons/CornerArrowIcon.vue'
// Magic Modal
import Overlay from '@/components/Modals/components/Overlay.vue'
import Modal from '@/components/Modals/components/Modal.vue'

import TabFolder from './components/TabFolder.vue'
import IATyping from './components/IATyping.vue'
import ModalHeader from '../../components/Modals/components/ModalHeader.vue'
import ModalBody from '../../components/Modals/components/ModalBody.vue'
import ModalFooter from '../../components/Modals/components/ModalFooter.vue'
import ModalTitle from '../../components/Modals/components/ModalTitle.vue'
import IAAction from './components/IAAction.vue'
import FinishImage from './components/FinishImage.vue'
import ToggleButton from '../../components/ToggleButton.vue'
import Tooltip from '@/components/Tooltip.vue'

import { ref, computed, onMounted } from 'vue'
import { api } from '../../api/instances'
import { useStore } from 'vuex'

const store = useStore()
const permissions = computed(() => store.state.permissions)
const active_business = computed(() => store.state.active_business)
const addToastMessage = (message) => store.commit('addToastMessage', message)

const generalConunter = ref(null)
const selectedTeam = ref(null)
const firstLoad = ref(true)

const isGeneralLicenses = computed(() => {
  if (!generalConunter.value) return false
  return generalConunter.value.available_licenses_for_teams > 0
})

const degree = computed(() => {
  if (!generalConunter.value) return 0
  return (180 / 100) * generalConunter.value.management_score
})

const teamSuggestions = computed(() => {
  if (!generalConunter.value) return 0
  return generalConunter.value.team_suggestions || []
})

const updateTeams = ref(new Set())

const updateMassiveTeamsManager = async (id, target_licenses, licenses_to_assign) => {
  await updateTeamLicenses(id, target_licenses, licenses_to_assign, true)
  updateMassiveTeams()
}

const loadingAllLicenses = ref(false)
const updateMassiveTeams = () => {
  loadingAllLicenses.value = true
  if (updateTeams.value.size < teamSuggestions.value.length) {
    const team = teamSuggestions.value[updateTeams.value.size]
    updateTeams.value.add(team.id)
    updateMassiveTeamsManager(team.id, team.target_licenses, team.licenses_to_assign)
  } else {
    loadingAllLicenses.value = false
    updateTeams.value.clear()
    getCounter()
  }
}
const isAutomaticRotation = ref(false)
const activateAutoRotation = () => {
  api.patch(`/jarvis/business/${active_business.value}/assistant/settings/`,
    {
      automatic_license_management: !isAutomaticRotation.value
    })
    .then(({ data }) => {
      isAutomaticRotation.value = data.automatic_license_management
      addToastMessage({
        status: 'success',
        title: 'Completado',
        message: 'Configuración actualizada correctamente.'
      })
    })
    .catch(() => {
      addToastMessage({
        title: '¡Error!',
        status: 'danger',
        message: 'Ha habido un problema al actualizar la configuración.'
      })
      console.error('Ha habido un problema al actualizar la configuración.')
    })
}
const getAutoRotateConfig = async () => {
  await api.get(`/jarvis/business/${active_business.value}/assistant/settings/`)
    .then(({ data }) => {
      isAutomaticRotation.value = data.automatic_license_management
    })
    .catch(() => {
      console.error('Ha habido un problema al cargar la configuración.')
    })
}
const magicData = ref(null)
const magicModal = ref(false)
const magicLoading = ref(false)
const executingMagic = ref(false)
const magicFinished = ref(false)
const magicGeneralIds = ref([])
const magicTeamsIds = ref([])

const closeModal = () => {
  magicModal.value = false
  magicLoading.value = false
  executingMagic.value = false
  magicFinished.value = false
  magicGeneralIds.value = []
  magicTeamsIds.value = []
  getCounter()
}

const isAllMagicOK = computed(() => {
  if (!magicData.value) return false
  return magicData.value.general.length === 0 && magicData.value.ids_to_disable.length === 0 && magicData.value.ids_to_enable.length === 0 && magicData.value.teams.length === 0
})
const canSeeIA = ref(true)
const getMagicData = async () => {
  magicModal.value = true
  if (!permissions.value.includes('can_see_assistant_ia')) {
    canSeeIA.value = false
    return
  }
  magicLoading.value = true
  try {
    const { data } = await api.get(`/jarvis/business/${idBusiness.value}/assistant/all/`)
    magicData.value = data
    magicModal.value = true
    magicLoading.value = false
    magicGeneralIds.value = data.general.map((team) => team.id)
    magicTeamsIds.value = [...data.ids_to_enable]
  } catch (error) {
    magicLoading.value = false
    magicModal.value = false
    addToastMessage({
      status: 'danger',
      title: 'Error!',
      message: 'Se ha producido un error de conexión con la IA'
    })
    console.log(error)
  }
}

const removeTeamIds = () => {
  if (magicTeamsIds.value.length > 0) {
    magicTeamsIds.value.shift()
  } else {
    executingMagic.value = false
    magicFinished.value = true
    getCounter()
  }
}

const magicUpdateMassiveTeamsManager = async (id, target_licenses, licenses_to_assign) => {
  await updateTeamLicenses(id, target_licenses, licenses_to_assign, true, true)
  magicUpdateMassiveTeams()
}

const magicUpdateMassiveTeams = async () => {
  executingMagic.value = true
  if (magicGeneralIds.value.length > 0) {
    const idTeam = magicGeneralIds.value.shift()
    const team = magicData.value.general.find((team) => team.id === idTeam)
    magicUpdateMassiveTeamsManager(team.id, team.suggestions.target, team.suggestions.change)
  } else {
    await rotateLicenses(magicData.value.ids_to_disable, magicData.value.ids_to_enable, true)
    removeTeamIds()
  }
}

const isAllOK = computed(() => {
  return generalConunter.value.pending_requests === 0 && generalConunter.value.available_licenses_for_teams === 0 && generalConunter.value.available_licenses_for_people === 0 && generalConunter.value.unactive_licenses === 0
})

const degreeState = computed(() => {
  if (degree.value < 30) {
    return { color: '#ff1a1a', text: 'Urge mejoría' }
  } else if (degree.value < 60) {
    return { color: '#ff1a1a', text: 'Urge mejoría' }
  } else if (degree.value < 90) {
    return { color: '#e4a149', text: 'Necesita mejorar' }
  } else if (degree.value < 120) {
    return { color: '#d1d80f', text: 'Requiere tu atención' }
  } else if (degree.value < 150) {
    return { color: '#84bd32', text: 'Buena' }
  } else if (degree.value <= 180) {
    return { color: '#30ad43', text: 'Excelente' }
  } else {
    return { color: '#ff1a1a', text: 'Necesita mejorar' }
  }
})

const idBusiness = computed(() => {
  return store.state.active_business
})

const getCounter = async () => {
  try {
    const { data } = await api.get(`/jarvis/business/${idBusiness.value}/assistant/general/`)
    generalConunter.value = data
    if (firstLoad.value && data.managed_teams.length > 0) {
      selectedTeam.value = data.managed_teams[0].id
      getTeamInfo()
      firstLoad.value = false
    }
  } catch (error) {
    console.log(error)
  }
}

const loadingLicenses = ref(false)
const updateTeamLicenses = async (teamId, targetLicensess, licensesToAssign, massive = false, ia = false) => {
  loadingLicenses.value = true
  try {
    await api.patch(`/jarvis/business/${idBusiness.value}/teams/${teamId}/modify-license/`, { number_licenses: targetLicensess })
    if (!massive) {
      addToastMessage({
        status: 'success',
        title: licensesToAssign > 1 ? 'Licencias reasignadas' : 'Licencia reasignada',
        message: licensesToAssign > 1 ? `${licensesToAssign} licencias se reasignaron correctamente.` : 'La licencia se reasignó correctamente.'
      })
      setTimeout(() => {
        getCounter()
        loadingLicenses.value = false
      }, 800)
    } else if (!ia) {
      addToastMessage({
        status: 'success',
        title: 'Licencias reasignadas',
        message: 'licencias se reasignaron correctamente.'
      })
    }
    loadingLicenses.value = null
  } catch (err) {
    console.error('Se ha producido un error al añadir o quitar licencias a este equipo')
  }
}

const teamData = ref(null)
const teamScore = computed(() => {
  if (!teamData.value) return 0
  return (180 / 100) * teamData.value.management_score
})
const teamName = computed(() => {
  if (!teamData.value) return 'Undefined'
  return teamData.value.name
})

const SelectedTeamData = computed(() => {
  if (!teamData.value) return 0
  return teamData.value
})
const getTeamInfo = async () => {
  try {
    const { data } = await api.get(`/jarvis/business/${idBusiness.value}/assistant/${selectedTeam.value}/`)
    teamData.value = data
  } catch (error) {
    console.log(error)
  }
}
const allEnableUserIds = computed(() => {
  if (!SelectedTeamData.value) return []
  return SelectedTeamData.value.enable_suggestions.map((user) => user.user_to_enable.id)
})
const loadingEnableAll = ref(false)
const activateLicenses = (ids, action = 'activate', ia = false) => {
  loadingEnableAll.value = true
  api.post(`/jarvis/business/${idBusiness.value}/massive/change/`,
    {
      action: 'activate',
      ids: [...ids]
    })
    .then(({ data }) => {
      if (action === 'activate') {
        addToastMessage({
          status: 'success',
          title: ids.length > 1 ? 'Licencias activadas' : 'Licencia activada',
          message: ids.length > 1 ? `${ids.length} licencias se activaron correctamente.` : 'La licencia se activó correctamente.'
        })
      } else if (!ia) {
        addToastMessage({
          status: 'success',
          title: ids.length > 1 ? 'Licencias rotadas' : 'Licencia rotada',
          message: ids.length > 1 ? `${ids.length} licencias se rotaron correctamente.` : 'La licencia se rotó correctamente.'
        })
      }
      loadingEnableAll.value = false
      getTeamInfo()
      getCounter()
    })
}

const deactivateIds = computed(() => {
  if (!SelectedTeamData.value) return []
  return SelectedTeamData.value.rotate_suggestions.map((user) => user.user_to_disable.id)
})
const activateIds = computed(() => {
  if (!SelectedTeamData.value) return []
  return SelectedTeamData.value.rotate_suggestions.map((user) => user.user_to_enable.id)
})
const loadingRotar = ref(false)
const rotateLicenses = async (deactivateIds, activateIds, ia = false) => {
  loadingRotar.value = true
  if (deactivateIds.length > 0) {
    api.post(`/jarvis/business/${idBusiness.value}/massive/change/`,
      {
        action: 'deactivate',
        ids: [...deactivateIds]
      })
      .then(async ({ data }) => {
        await activateLicenses(activateIds, 'rotate', ia)
        loadingRotar.value = false
      })
  } else if (activateIds.length > 0) {
    await activateLicenses(activateIds, 'rotate', ia)
    loadingRotar.value = false
  }
}
onMounted(() => {
  getAutoRotateConfig()
  getCounter()
})
</script>

<style scoped>
.managed-team_list:not(:last-child):not(:first-child)::after {
  content: ',';
  display: inline-block;
  margin-right: 5px;
}

.managed-team_list:last-child::before {
  content: 'y';
  display: inline-block;
  margin-right: 5px;
}

.massive-action-btn {
  @apply w-8 h-8 flex justify-center items-center mx-4 transition-all duration-300;
  /* @apply w-8 h-8 flex justify-center items-center mx-4 hover:bg-litepie-primary-400 rounded-lg transition-all duration-300;*/
}
.max-modal-scroll {
  @apply max-h-[65vh] overflow-y-scroll;
}
</style>
