<template>
  <UiHeader data-html2canvas-ignore="true" :title="'Analítica'"/>
  <UiPageBody >
    <span id="general-data">
      <section class="mb-8 mt-6">
        <div class="xl:w-12/12">
          <h2 class="text-2xl text-grey-darker font-semibold pb-2">Global</h2>
        </div>
      <div class="flex xl:flex-row flex-col justify-between">
        <div>
          <DownloadData :excel="{total_hours, total_formations, total_certificates, media_polls}" />
        </div>
        <div class="xl:w-9/12 flex items-center justify-end" data-html2canvas-ignore="true">
          <h2 class="mr-4 text-grey">Filtrar por:</h2>
          <div class="col-auto px-1 w-1/6">
            <SelectTeam class="w-auto" v-model="filters.users.team_id" :bgClass="'bg-white'" @change="updateData" />
          </div>
          <div class="col-auto px-1">
            <TagsField v-model="filters.users.tags" :bgClass="'bg-white'" @change="updateData"/>
          </div>
          <div class="col-auto px-1">
            <litepie-datepicker
            use-range
            placeholder="Desde - Hasta"
            separator=" - "
            :formatter="formatter_datepicker"
            :auto-apply="false"
            :shortcuts="customShortcuts"
            :options="options"
            i18n="es"
            v-model="date_value"
            v-slot="{ value, placeholder, clear }"
            @update="updateData"
            >
            <div class="bg-white border-2 flex items-center py-2 px-3 rounded"
            :class="(value !== '') ? 'border-primary' : 'border-primary-lighter'">
              <button
                type="button"
              >
                <span class="flex items-center text-grey-dark">
                  {{ value || placeholder }}
                </span>
              </button>
              <CalendarIcon v-if="value === ''" class="ml-5"/>
              <button @click="clear">
                <SVGIcons v-if="value !== ''" icon="cross-dark" class="svg-10 ml-5"/>
              </button>
            </div>
            </litepie-datepicker>
          </div>
        </div>
        </div>
        <div class="w-full mt-2 pl-1 mb-3 text-grey"><span class="text-sm">{{time_filter}}</span></div>
        <div class="flex xl:flex-nowrap flex-wrap w-full mr-9">
            <div class="xl:w-1/4 w-2/4 xl:pr-3 xl:mb-0 mb-6 pr-3 flex">
              <MetricsBox :loading="loading_metrics" :stat="total_hours" :text="'Horas de conexión'">
                <Tooltip class="ml-1">
                  <template v-slot:icon>
                    <TimeIcon />
                  </template>
                  <template v-slot:default>
                    <li>
                      <p><strong>Horas de conexión:</strong> Número histórico del total de horas conectados en la plataforma por los usuarios de la organización.</p>
                    </li>
                  </template>
                </Tooltip>

              </MetricsBox>
            </div>
            <div class="xl:w-1/4 w-2/4 xl:px-3 xl:mb-0 mb-6 pl-3 flex">
              <MetricsBox :loading="loading_metrics" :stat="total_formations" :text="'Matrículas'">
                  <Tooltip class="ml-1">
                    <template v-slot:icon>
                      <BookIcon />
                    </template>
                    <template v-slot:default>
                      <li>
                        <p> <strong>Matrículas:</strong> Número histórico del total de formaciones que los usuarios han
                          comenzado.</p>
                      </li>
                    </template>
                  </Tooltip>
              </MetricsBox>
            </div>
            <div class="xl:w-1/4 w-2/4 xl:px-3 pr-3 flex">
              <MetricsBox :loading="loading_metrics" :stat="total_certificates" :text="'Certificados obtenidos'">
                  <Tooltip class="ml-1">
                    <template v-slot:icon>
                      <CertificateIcon />
                    </template>
                    <template v-slot:default>
                      <li>
                        <p><strong>Certificados obtenidos:</strong> Número histórico del total de certificados obtenidos por los usuarios
                          de la organización.</p>
                      </li>
                    </template>
                  </Tooltip>
              </MetricsBox>
            </div>
            <div class="xl:w-1/4 w-2/4 xl:pl-3 pl-3 flex">
              <MetricsBox :loading="loading_metrics" :stat="media_polls" :text="'Valoración media formaciones Openwebinars '">
                <Tooltip class="ml-1">
                  <template v-slot:icon>
                    <StartIcon />
                  </template>
                  <template v-slot:default>
                    <li>
                      <p><strong>Valoración media formaciones Openwebinars:</strong> Número medio del total de valoraciones realizadas por los
                        usuarios de la organización.</p>
                    </li>
                  </template>
                </Tooltip>
              </MetricsBox>
            </div>
          </div>
    </section>
    <section class="my-8" >
      <div class="flex xl:flex-nowrap flex-wrap w-full mr-9 mt-9">
        <div class="xl:w-1/4 w-2/4 flex pr-3">
          <UiBox class="flex flex-col justify-start" rounded :class="{'loading' : loading}">
            <div class="flex flex-col items-center justify-center">
              <h2 class="font-bold text-primary">Registro de los usuarios</h2>
              <APXDonutChart class="-mx-8 mt-4" :label="(date_value.startDate === '') ? 'Usuarios totales' : 'Nuevos usuarios'" :chart-data="[
                { name: 'Registrados', color: '#3F536F', count: register.user_login || 0},
                { name: 'Invitados', color: '#8FA0BC', count: register.user_without_login || 0},
              ]"/>
                <ul class="flex justify-center flex-col mx-auto">
                  <li class="flex items-center text-grey-dark font-medium" v-for="({name, color, count}, index) in [
                    { name: 'Invitados', color: '#8FA0BC', count: register.user_without_login || 0},
                    { name: 'Registrados', color: '#3F536F', count: register.user_login || 0},
                  ]" :key="index">
                  <Tooltip>
                      <template v-slot:icon>
                        <div class="rounded-full bg-primary p-1.5 circle" :style="`background-color: ${color}`">
                        </div>
                      </template>
                      <li>
                        <p>{{
                        (name === 'Registrados')
                        ? 'Usuarios que han aceptado la invitación y han iniciado sesión.'
                        : 'Usuarios que han sido invitados a darse de alta.'
                        }}</p>
                      </li>
                    </Tooltip>
                    <p class="ml-2 mr-5 text-sm 2xl:text-base">{{name}}</p>
                    <span class="ml-auto font-bold text-primary">{{count}}</span>
                  </li>
                </ul>
            </div>
          </UiBox>
        </div>
        <div class="xl:w-1/4 w-2/4 flex px-3">
          <UiBox class="flex flex-col justify-start" rounded :class="{'loading' : loading}">
            <div class="flex flex-col items-center justify-center">
              <h2 class="font-bold text-primary">Formación de los usuarios</h2>
              <APXDonutChart class="-mx-8 mt-4" :label="'Usuarios registrados'" :chart-data="[
                { name: 'Sin actividad', color: '#8fa0bc', count: formation.users_not_enrolled || 0},
                { name: 'Con actividad', color: '#3F536F', count: formation.users_enrolled || 0},
              ]"/>
                <ul class="flex justify-center flex-col mx-auto">
                  <li class="flex items-center text-grey-dark font-medium" v-for="({name, color, count}, index) in [
                  { name: 'Con actividad', color: '#3F536F', count: formation.users_enrolled || 0 },
                  { name: 'Sin actividad', color: '#8fa0bc', count: formation.users_not_enrolled || 0},
                  ]" :key="index">
                    <Tooltip>
                      <template v-slot:icon>
                        <div class="rounded-full bg-primary p-1.5 circle" :style="`background-color: ${color}`">
                        </div>
                      </template>
                      <li>
                        <p>{{
                        (name === 'Con actividad')
                        ? 'Usuarios que han realizado acciones formativas.'
                        : 'Usuarios que no han realizado acciones formativas.'
                        }}</p>
                      </li>
                    </Tooltip>
                    <p class="ml-2 mr-5 text-sm 2xl:text-base">{{name}}</p>
                    <span class="ml-auto font-bold text-primary">{{count}}</span>
                  </li>
                </ul>
            </div>
          </UiBox>
        </div>
        <div class="xl:w-2/4 w-full flex xl:mt-0 mt-6 pl-3">
        <UiBox class="w-full" rounded :class="{'loading' : loading}">
          <TreeMap class="w-full" :skills="skills"/>
        </UiBox>
        </div>
      </div>
    </section>
  </span>
  </UiPageBody>
</template>
<script>

import MetricsBox from '@/components/Layout/MetricsBox.vue'
import SelectTeam from '@/components/SelectTeam.vue'
import TagsField from '@/components/TagsField.vue'
// import UiDonutChart from '@/components/Layout/UI/UiDonutChart.vue'
import { mapState } from 'vuex'
import { api } from '../../api/instances'
import LitepieDatepicker from 'litepie-datepicker'
import TreeMap from '../../components/Charts/TreeMap.vue'
import UiPageBody from '../../components/Layout/UI/UiPageBody.vue'
import UiHeader from '../../components/Layout/UI/UiHeader.vue'
import UiBox from '../../components/Layout/UI/UiBox.vue'
import APXDonutChart from '../../components/Charts/APXDonutChart.vue'
import Tooltip from '../../components/Tooltip.vue'
import SVGIcons from '../../components/Layout/SVGIcons.vue'
import StartIcon from './icons/Start.vue'
import CertificateIcon from './icons/Certificate.vue'
import BookIcon from './icons/Book.vue'
import TimeIcon from './icons/Time.vue'
import CalendarIcon from './icons/Calendar.vue'
import DownloadData from './components/DownloadData.vue'
export default {
  components: {
    MetricsBox,
    SelectTeam,
    TagsField,
    // UiDonutChart,
    TreeMap,
    UiPageBody,
    UiHeader,
    UiBox,
    APXDonutChart,
    Tooltip,
    LitepieDatepicker,
    SVGIcons,
    StartIcon,
    CertificateIcon,
    TimeIcon,
    BookIcon,
    CalendarIcon,
    DownloadData
  },
  data () {
    return {
      total_hours: 0,
      total_formations: 0,
      total_certificates: 0,
      media_polls: 0,
      loading: false,
      register: {},
      formation: {},
      categories: [],
      time_filter: ' ',
      limit: 4,
      skills: [],
      dates: {},
      loading_metrics: false,
      formatter_datepicker: {
        date: 'DD-MM-YYYY',
        month: 'MMMM'
      },
      historic_date: '',
      options: {
        footer: {
          apply: 'Seleccionar',
          cancel: 'Cancelar'
        }
      },
      date_value: {
        startDate: '',
        endDate: ''
      }
    }
  },
  computed: {
    ...mapState(['total_licenses', 'active_business', 'filters']),
    dataRegister () {
      return (this.register.user_login && this.register.user_without_login)
        ? [this.register.user_login, this.register.user_without_login]
        : [0, 0]
    },
    dataFormation () {
      return (this.formation.users_enrolled && this.formation.users_not_enrolled)
        ? [this.formation.users_enrolled, this.formation.users_not_enrolled]
        : [0, 0]
    }
  },
  watch: {
    date_value () {
      this.updateData()
    }
  },
  methods: {
    async getCounters () {
      this.loading_metrics = true
      await api.get(`/jarvis/business/${this.active_business}/analitics/home/counters/`,
        {
          params: {
            team: this.filters.users.team_id,
            custom_tags: this.filters.users.tags,
            start: this.formmated_date_value(this.date_value.startDate),
            end: this.formmated_date_value(this.date_value.endDate)
          }
        })
        .then(({ data }) => {
          this.total_hours = Math.round(data.total_hours)
          this.historic_date = data.date
          this.total_formations = data.total_formations
          this.total_certificates = data.total_certificates
          this.time_filter = (this.date_value.startDate && this.date_value.endDate)
            ? `Datos mostrados a partir de ${this.$filters.shortDate(this.formmated_date_value(this.date_value.startDate))} hasta ${this.$filters.shortDate(this.formmated_date_value(this.date_value.endDate))}. `
            : `Datos mostrados a partir de ${this.$filters.shortDate(this.historic_date)} hasta la actualidad.`
          this.media_polls = data.media_polls && Number(data.media_polls.toFixed(2))
          setTimeout(() => {
            this.loading_metrics = false
          }, 2000)
        })
        .catch((err) => {
          console.error('Problema al cargar los datos', err)
        })
    },
    async getUsers () {
      this.loading = true
      await api.get(`/jarvis/business/${this.active_business}/analitics/home/users`,
        {
          params: {
            team: this.filters.users.team_id,
            custom_tags: this.filters.users.tags,
            start: this.formmated_date_value(this.date_value.startDate),
            end: this.formmated_date_value(this.date_value.endDate)
          }
        })
        .then(({ data }) => {
          this.register = data.register
          this.formation = data.formation
          setTimeout(() => {
            this.loading = false
          }, 2000)
        })
        .catch((err) => {
          console.error(err)
          this.loading = false
        })
      this.first_loading = false
    },

    async getCategories () {
      this.loading = true
      await api.get(`/jarvis/business/${this.active_business}/analitics/home/categories/`, {

        params: {
          limit: this.limit,
          team: this.filters.users.team_id,
          custom_tags: this.filters.users.tags,
          start: this.formmated_date_value(this.date_value.startDate),
          end: this.formmated_date_value(this.date_value.endDate)
        }
      })
        .then(({ data }) => {
          this.categories = data.results
          setTimeout(() => {
            this.loading = false
          }, 2000)
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
        })
    },
    viewMore () {
      if (this.limit === 4) {
        this.limit = 100000
        this.getCategories()
      } else {
        this.limit = 4
        this.getCategories()
      }
    },
    async getSkills () {
      this.loading = true
      await api.get(`/jarvis/business/${this.active_business}/analitics/home/skills/`,
        {
          params: {
            limit: 2,
            team: this.filters.users.team_id,
            custom_tags: this.filters.users.tags,
            start: this.formmated_date_value(this.date_value.startDate),
            end: this.formmated_date_value(this.date_value.endDate)

          }
        })
        .then(({ data }) => {
          this.skills = data
        })
        .catch(() => {
          console.error('Problema al cargar los datos')
        })
    },

    updateData () {
      this.getCounters()
      this.getUsers()
      this.getCategories()
      this.getSkills()
    },
    customShortcuts () {
      return [
        {
          label: 'Últimos 7 días',
          atClick: () => {
            const date = new Date()
            return [
              new Date(date.setDate(date.getDate() - 7)),
              new Date()
            ]
          }
        },
        {
          label: 'Últimos 14 días',
          atClick: () => {
            const date = new Date()
            return [
              new Date(date.setDate(date.getDate() - 14)),
              new Date()
            ]
          }
        },
        {
          label: 'Últimos 30 días',
          atClick: () => {
            const date = new Date()
            return [
              new Date(date.setDate(date.getDate() - 30)),
              new Date()
            ]
          }
        },
        {
          label: 'Últimos 12 meses',
          atClick: () => {
            const date = new Date()
            return [
              new Date(date.setFullYear(date.getFullYear() - 1)),
              new Date()
            ]
          }
        },
        {
          label: 'Seleccionar días'
        }
      ]
    },
    formmated_date_value (date) {
      return date.split(/[-/]/).reverse().join('-')
    }
  },

  created () {
    this.updateData()
  }
}
</script>
