<template>
<div v-if="is_visible && !scheduled.set_inactive" class="inline" :class="{'disable' : user.has_planning || user.has_future_planning }">
  <Tooltip :disabled="!(user.has_planning || user.has_future_planning)" class="ml-1">
  <template v-slot:icon>
    <button @click="update()" class="toggle-button toggle-license-state" :class="{'active': active}">
      <div class="toppo p-1">
        <svg class="animate-spin" v-if="loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path  style="fill:rgb(148,148,148);" d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z"/>
        </svg>
        <svg v-if="user.has_planning || user.has_future_planning" width="100%" height="100%" viewBox="0 0 30 30">
          <g transform="matrix(1,0,0,1,1.51432,1.51438)">
              <g transform="matrix(3.08241,0,0,3.08241,-0.385302,-0.385302)">
                  <path d="M0.125,2.392C0.125,2.682 0.36,2.917 0.65,2.917L3.308,2.917C3.598,2.917 3.833,2.682 3.833,2.392C3.833,2.392 3.833,0.65 3.833,0.65C3.833,0.36 3.598,0.125 3.308,0.125L0.65,0.125C0.36,0.125 0.125,0.36 0.125,0.65C0.125,0.65 0.125,2.392 0.125,2.392ZM0.625,2.392L0.625,0.65C0.625,0.636 0.636,0.625 0.65,0.625C0.65,0.625 3.308,0.625 3.308,0.625C3.322,0.625 3.333,0.636 3.333,0.65L3.333,2.392C3.333,2.405 3.322,2.417 3.308,2.417C3.308,2.417 0.65,2.417 0.65,2.417C0.636,2.417 0.625,2.405 0.625,2.392Z"
                  :style="`fill:${(is_active) ?'rgb(52,199,89)':'rgb(148,148,148)'};`"/>
              </g>
              <g transform="matrix(3.08241,0,0,3.08241,-0.385302,-0.385302)">
                  <path d="M5.167,8.35C5.167,8.64 5.402,8.875 5.692,8.875L8.35,8.875C8.64,8.875 8.875,8.64 8.875,8.35C8.875,8.35 8.875,6.608 8.875,6.608C8.875,6.318 8.64,6.083 8.35,6.083C8.35,6.083 5.692,6.083 5.692,6.083C5.402,6.083 5.167,6.318 5.167,6.608L5.167,8.35ZM5.667,8.35L5.667,6.608C5.667,6.595 5.678,6.583 5.692,6.583L8.35,6.583C8.364,6.583 8.375,6.595 8.375,6.608L8.375,8.35C8.375,8.364 8.364,8.375 8.35,8.375C8.35,8.375 5.692,8.375 5.692,8.375C5.678,8.375 5.667,8.364 5.667,8.35Z"
                  :style="`fill:${(is_active) ?'rgb(52,199,89)':'rgb(148,148,148)'};`"/>
              </g>
              <g transform="matrix(3.08241,0,0,3.08241,-0.385302,-0.385302)">
                  <path d="M5.167,4.683C5.167,4.973 5.402,5.208 5.692,5.208C5.692,5.208 8.35,5.208 8.35,5.208C8.64,5.208 8.875,4.973 8.875,4.683C8.875,4.683 8.875,0.65 8.875,0.65C8.875,0.36 8.64,0.125 8.35,0.125L5.692,0.125C5.402,0.125 5.167,0.36 5.167,0.65L5.167,4.683ZM5.667,4.683L5.667,0.65C5.667,0.636 5.678,0.625 5.692,0.625C5.692,0.625 8.35,0.625 8.35,0.625C8.364,0.625 8.375,0.636 8.375,0.65L8.375,4.683C8.375,4.697 8.364,4.708 8.35,4.708L5.692,4.708C5.678,4.708 5.667,4.697 5.667,4.683Z"
                  :style="`fill:${(is_active) ?'rgb(52,199,89)':'rgb(148,148,148)'};`"/>
              </g>
              <g transform="matrix(3.08241,0,0,3.08241,-0.385302,-0.385302)">
                  <path d="M0.125,8.35C0.125,8.64 0.36,8.875 0.65,8.875L3.308,8.875C3.598,8.875 3.833,8.64 3.833,8.35C3.833,8.35 3.833,4.317 3.833,4.317C3.833,4.027 3.598,3.792 3.308,3.792C3.308,3.792 0.65,3.792 0.65,3.792C0.36,3.792 0.125,4.027 0.125,4.317C0.125,4.317 0.125,8.35 0.125,8.35ZM0.625,8.35L0.625,4.317C0.625,4.303 0.636,4.292 0.65,4.292L3.308,4.292C3.322,4.292 3.333,4.303 3.333,4.317L3.333,8.35C3.333,8.364 3.322,8.375 3.308,8.375C3.308,8.375 0.65,8.375 0.65,8.375C0.636,8.375 0.625,8.364 0.625,8.35Z"
                  :style="`fill:${(is_active) ?'rgb(52,199,89)':'rgb(148,148,148)'};`"/>
              </g>
          </g>
      </svg>
      </div>
    </button>
  </template>
  <template v-slot:default>
    <li v-if="user.has_planning">
      <p>Esta licencia está planificada</p>
    </li>
    <li v-if="user.has_future_planning">
      <p>Esta licencia está planificada para un sprint futuro</p>
    </li>
    <li>
      <p>Si quieres asignarla manualmente, debes ir al <u>planificador</u> y eliminar la planificación.</p>
    </li>
  </template>
  </Tooltip>
</div>
<div v-if="is_visible && scheduled.set_inactive" class="inline">
  <Tooltip :disabled="!active" class="ml-1">
  <template v-slot:icon>
    <button @click="update()" class="toggle-button toggle-license-state" :class="{'active': active}">
      <div class="toppo p-1">
        <svg class="animate-spin" v-if="loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path  style="fill:rgb(148,148,148);" d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z"/>
        </svg>
        <svg v-if="active" width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.625 0.916672H7.375" stroke="#7A7A7A" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M6 4.58333V6.41666" stroke="#7A7A7A" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M6.00016 10.0833C8.02522 10.0833 9.66683 8.44172 9.66683 6.41667C9.66683 4.39162 8.02522 2.75 6.00016 2.75C3.97512 2.75 2.3335 4.39162 2.3335 6.41667C2.3335 8.44172 3.97512 10.0833 6.00016 10.0833Z" stroke="#7A7A7A" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </button>
  </template>
  <template v-slot:default>
    <li class="text-[10px] text-center">
      <p>Fecha de desactivación {{ $filters.shortDate(scheduled.set_inactive_at) }}</p>
    </li>
  </template>
  </Tooltip>
</div>

<div v-if="!is_visible">
  <Tooltip :disabled="!active" class="ml-1">
    <template v-slot:icon>
    <div class="toggle-button toggle-license-state mt-2" :class="{'active': active}">
      <div class="toppo p-1">
        <svg v-if="active" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 10V9.5C1 8.57174 1.36875 7.6815 2.02513 7.02513C2.6815 6.36875 3.57174 6 4.5 6" stroke="#9B9B9B" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7.902 6.1565C7.97781 6.07329 8.07016 6.00682 8.17313 5.96134C8.2761 5.91586 8.38743 5.89236 8.5 5.89236C8.61257 5.89236 8.72389 5.91586 8.82687 5.96134C8.92984 6.00682 9.02218 6.07329 9.098 6.1565C9.2605 6.335 9.493 6.4315 9.734 6.42C9.8465 6.41472 9.95885 6.433 10.0639 6.47365C10.1689 6.5143 10.2643 6.57643 10.3439 6.65606C10.4236 6.7357 10.4857 6.83108 10.5264 6.93611C10.567 7.04114 10.5853 7.1535 10.58 7.266C10.5685 7.5065 10.665 7.7395 10.843 7.902C11.1955 8.223 11.1955 8.777 10.843 9.098C10.665 9.2605 10.5685 9.493 10.58 9.734C10.5853 9.8465 10.567 9.95886 10.5264 10.0639C10.4857 10.1689 10.4236 10.2643 10.3439 10.3439C10.2643 10.4236 10.1689 10.4857 10.0639 10.5264C9.95885 10.567 9.8465 10.5853 9.734 10.58C9.61517 10.5744 9.49657 10.595 9.38664 10.6405C9.27671 10.686 9.17815 10.7551 9.098 10.843C9.02218 10.9262 8.92984 10.9927 8.82687 11.0382C8.72389 11.0836 8.61257 11.1071 8.5 11.1071C8.38743 11.1071 8.2761 11.0836 8.17313 11.0382C8.07016 10.9927 7.97781 10.9262 7.902 10.843C7.82184 10.7551 7.72329 10.686 7.61336 10.6405C7.50343 10.595 7.38482 10.5744 7.266 10.58C7.1535 10.5853 7.04114 10.567 6.93611 10.5264C6.83108 10.4857 6.7357 10.4236 6.65606 10.3439C6.57643 10.2643 6.51429 10.1689 6.47365 10.0639C6.433 9.95886 6.41472 9.8465 6.42 9.734C6.42555 9.61513 6.40482 9.49651 6.35927 9.38657C6.31373 9.27664 6.24449 9.17811 6.1565 9.098C6.07329 9.02218 6.00682 8.92984 5.96134 8.82687C5.91586 8.7239 5.89236 8.61257 5.89236 8.5C5.89236 8.38743 5.91586 8.2761 5.96134 8.17313C6.00682 8.07016 6.07329 7.97781 6.1565 7.902C6.335 7.7395 6.4315 7.507 6.42 7.266C6.41472 7.1535 6.433 7.04114 6.47365 6.93611C6.51429 6.83108 6.57643 6.7357 6.65606 6.65606C6.7357 6.57643 6.83108 6.5143 6.93611 6.47365C7.04114 6.433 7.1535 6.41472 7.266 6.42C7.5065 6.4315 7.7395 6.335 7.902 6.1565Z" stroke="#9B9B9B" stroke-width="0.75"/>
          <path d="M7.682 8.5L8.227 9.045L9.318 7.955M4.5 6C5.03043 6 5.53914 5.78929 5.91421 5.41421C6.28929 5.03914 6.5 4.53043 6.5 4C6.5 3.46957 6.28929 2.96086 5.91421 2.58579C5.53914 2.21071 5.03043 2 4.5 2C3.96957 2 3.46086 2.21071 3.08579 2.58579C2.71071 2.96086 2.5 3.46957 2.5 4C2.5 4.53043 2.71071 5.03914 3.08579 5.41421C3.46086 5.78929 3.96957 6 4.5 6Z" stroke="#9B9B9B" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    </template>
    <template v-slot:default>
    <li>
      <p>Esta licencia es nominativa y no puede desactivarse, si prefieres tener licencias rotativas <u>contacta con tu Customer Succes</u>.</p>
    </li>
  </template>
    </Tooltip>
</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { api } from '../api/instances'
import Tooltip from './Tooltip.vue'

export default {
  components: { Tooltip },
  name: 'ToggleLicenseState',
  props: {
    user: {
      type: Object
    }
  },
  emits: ['change'],
  data () {
    return {
      loading: false,
      active: false,
      timer: null,
      scheduled: {}
    }
  },
  computed: {
    ...mapState(['permissions']),
    is_visible () {
      return this.permissions.includes('can_modify_licenses_status')
    }
  },
  watch: {
    user () {
      this.active = this.user.is_active
      this.scheduled = (this.user.scheduled) ? this.user.scheduled : { set_inactive: false }
    }
  },
  methods: {
    ...mapMutations(['addToastMessage']),
    ...mapActions(['getTotalLicenses']),
    update () {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.updateLicenseState(!this.active)
      }, 450)
    },
    async updateLicenseState (state) {
      this.loading = true
      await api.patch(`/business/license/${this.user.employee_id}/?jarvis=jarvis`, { is_active: state })
        .then(({ data, status }) => {
          this.getTotalLicenses()
          this.active = data.is_active
          this.loading = false
          if (data.status === 'danger') {
            this.addToastMessage({
              status: 'danger',
              title: 'Error',
              message: data.text || 'Se ha producido un error.'
            })
          } else {
            this.addToastMessage({
              status: 'info',
              title: (data.is_active) ? 'Activada' : 'Desactivada',
              message: (data.is_active) ? 'La licencia se activó correctamente.' : 'La licencia se desactivó correctamente.'
            })
          }
        })
        .catch((error) => {
          if (error.response) {
            this.addToastMessage({
              status: 'danger',
              title: 'Error',
              message: 'Se ha producido un error al a cambiar el estado de su licencia.'
            })
            this.loading = false
            /*
              console.error(error.response.data)
              console.error(error.response.status)
              console.error(error.response.headers)
            */
          }
        })

      this.$emit('change')
    }

  },
  created () {
    this.active = this.user.is_active
    this.scheduled = (this.user.scheduled) ? this.user.scheduled : { set_inactive: false }
  }
}
</script>

<style scoped>
  .toggle-button{
    transition: all .3s ease-in-out;
    @apply bg-grey-lighter h-6 p-1 rounded-full w-11 relative
  }

  .toggle-button.active{
    @apply bg-green-license
  }
  .toggle-button.active .toppo{
    left: calc(100% - 22px);
  }

  .toppo{
    top: 2px;
    left: 3px;
    transition: all .3s ease-in-out;
    width: 20px;
    height: 20px;
    @apply bg-white rounded-full absolute shadow
  }

  .disable button {
    pointer-events: none;
  }
  .disable .toggle-button.active {
    background-color: #93dca7;
  }
</style>
